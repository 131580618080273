import axios from 'axios';
import { stringify } from 'query-string';

const axiosInstance = axios.create({
  baseURL: 'https://public-api.wordpress.com/rest/v1.1',
});

export default {
  getPosts: (params: any) =>
    axiosInstance({
      method: 'GET',
      url: `/sites/${process.env.REACT_APP_WP_SITE}/posts/?${stringify(params)}`,
    }),
  getPost: (id: string) =>
    axiosInstance({
      method: 'GET',
      url: `/sites/${process.env.REACT_APP_WP_SITE}/posts/${id}`,
    }),
  getCategories: () =>
    axiosInstance({
      method: 'GET',
      url: `/sites/${process.env.REACT_APP_WP_SITE}/categories/`,
    }),
};
