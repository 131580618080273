import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      borderRadius: 8,
      border: `1px solid ${theme.colors.grey5}`,
    },
    imgContainer: {
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
      paddingTop: '56.25%',
      '& img': {
        borderRadius: 7,
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        objectFit: 'cover',
      },
    },
    content: {
      padding: '17px 13px ',
    },
    title: {
      height: 50,
      display: 'box',
      boxOrient: 'vertical',
      lineClamp: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 700,
    },
    date: {
      color: theme.colors.gray2,
      fontSize: 14,
      lineHeight: '24px',
    },
    excerpt: {
      margin: '14px 0',
      width: '100%',
      height: 130,
      '& p': {
        display: 'box',
        boxOrient: 'vertical',
        lineClamp: 5,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 16,
        lineHeight: '26px',
      },
    },
    link: {
      color: theme.colors.cyan,
    },
  })
);
