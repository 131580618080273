import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  backLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'bold',
    letterSpacing: '-0.355556px',
    color: theme.colors.cyan,
    textDecoration: 'unset',
    cursor: 'pointer',
    '& span': {
      marginLeft: '12px',
    },
  },
}));
