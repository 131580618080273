import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// assets
import headerSrc from './header.png';
// styles
import { useStyles } from './styles';

const Header = () => {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.headerImgContainer}>
      <LazyLoadImage src={headerSrc} className={classes.headerImg} />
      <Typography variant="h1" className={classes.headerText}>
        Kivity Corner
      </Typography>
    </Grid>
  );
};

export default Header;
