import React, { useMemo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import cn from 'classnames';
import useSWR from 'swr';
// components
import { Button } from 'components/Button';
import { Spinner } from 'components/Spinner';
import PostCard from '../components/PostCard';
import Header from '../components/Header';
// api
import blogApi from 'utils/blogApi';
import { useStyles } from './styles';

const PostsList = () => {
  const classes = useStyles();

  const location = useLocation();

  const [postsCount, setPostsCount] = useState(8);

  const queryParams = useMemo(() => parse(location.search), [location.search]);

  const { data: categoriesResponse } = useSWR<any>('/categories', () => blogApi.getCategories(), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  });

  const { data: postResponse, isValidating } = useSWR<any>(
    `/posts?number=${postsCount}&category=${queryParams?.category}`,
    () => blogApi.getPosts({ ...queryParams, number: postsCount }),
    { revalidateOnMount: true, revalidateOnFocus: false }
  );

  return (
    <>
      <Header />
      <Grid container className={classes.container}>
        {!postResponse?.data?.posts && isValidating ? (
          <Spinner isFullWidth />
        ) : postResponse?.data?.posts?.length > 0 ? (
          <>
            <Grid container className={classes.categories} justify="space-between" wrap="nowrap">
              {categoriesResponse?.data?.categories?.map((item: any) => (
                <Grid
                  key={item.ID}
                  component={Link}
                  to={item.slug !== queryParams?.category ? `/?category=${item.slug}` : '/'}
                  item
                  className={cn(classes.categoriesItem, {
                    [classes.activeCategory]: item.slug === queryParams?.category,
                  })}
                  dangerouslySetInnerHTML={{ __html: item.name.replace(/\s/g, ' ') }}
                />
              ))}
            </Grid>
            <Grid container spacing={4}>
              {isValidating && <Spinner backdrop />}
              {postResponse?.data?.posts.map((item: any) => (
                <Grid key={item.ID} container item md={4} xs={6}>
                  <PostCard item={item} />
                </Grid>
              ))}
            </Grid>
            {postResponse?.data?.found > postsCount && (
              <Grid container justify="center" className={classes.btnContainer}>
                <Button
                  type="button"
                  width="196px"
                  label="LOAD MORE"
                  onClick={() => {
                    setPostsCount((prev) => prev + 8);
                  }}
                />
              </Grid>
            )}
          </>
        ) : (
          <Typography variant="subtitle1">No businesses found</Typography>
        )}
      </Grid>
    </>
  );
};

export default PostsList;
