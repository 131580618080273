import React, { FunctionComponent, memo } from 'react';
// icons
import { Logo } from 'assets/icons';
// material-ui components
import { Grid, Hidden, Box } from '@material-ui/core';
// styles
import useStyles from './styles';

const headerLinks = [
  { path: '/about', label: 'About' },
  { path: '/help', label: 'Help' },
];

const HeaderView: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Grid component="header" container justify="center" className={classes.header}>
      <Grid container justify="space-between" alignItems="center" className={classes.container}>
        <a href={`${process.env.REACT_APP_MAIN_SITE_URL}/`} className={classes.logo}>
          <Logo />
        </a>
        <Hidden mdDown>
          <Box display="flex">
            {headerLinks.map(({ path, label }) => (
              <a
                key={path}
                href={`${process.env.REACT_APP_MAIN_SITE_URL}${path}`}
                className={classes.link}
              >
                <Box display="flex" alignItems="center" className={classes.linkContainer}>
                  {label}
                </Box>
              </a>
            ))}
          </Box>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default memo(HeaderView);
