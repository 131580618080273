import React, { FunctionComponent, lazy, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
// '@material-ui
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// components
import { Spinner } from 'components/Spinner';
// layouts
import Header from 'layouts/Header';
import PostsList from 'pages/Blog/PostsList';
import Post from 'pages/Blog/Post';
// lazy-load
// lazy components
const Footer = lazy(() => import('layouts/Footer'));

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || '');

Sentry.init({
  dsn: 'https://643733fed1fa4bf5b87239cb8f263755@o486179.ingest.sentry.io/5542529',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
});

if (process.env.REACT_APP_FB_PIXEL) {
  const reactPixelOptions = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL, undefined, reactPixelOptions);
  ReactPixel.pageView();
}

const App: FunctionComponent = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Helmet titleTemplate="%s - Kivity" defaultTitle="Kivity">
        <meta
          name="description"
          content="Kivity is an online marketplace that helps parents discover and enroll in local after school activities."
        />
        <meta name="locale" content="en_US" />
        <meta name="type" content="website" />
        <meta name="site_name" content="Kivity.com" />
      </Helmet>
      <Header />
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/" component={PostsList} exact />
          <Route path="/posts/:id" component={Post} />
          <Redirect to="/" />
        </Switch>
        <Footer />
      </Suspense>
    </MuiPickersUtilsProvider>
  );
};

export default App;
