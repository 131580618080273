import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    headerText: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      color: theme.colors.cyan,
      width: '100%',
      textAlign: 'center',
    },
    headerImgContainer: {
      position: 'relative',
    },
    container: {
      padding: '65px 10%',
      [theme.breakpoints.down('sm')]: {
        padding: '50px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '50px 20px',
      },
    },
    categories: {
      overflow: 'auto',
      marginBottom: 52,
      position: 'relative',
      '&::after': {
        content: '""',
        width: '100%',
        height: 2,
        position: 'absolute',
        bottom: 0,
        background: theme.colors.grey5,
        zIndex: -1,
      },
    },
    categoriesItem: {
      position: 'relative',
      fontSize: 18,
      lineHeight: '26px',
      whiteSpace: 'nowrap',
      padding: '15px 7px',
      color: theme.colors.black,
      textDecoration: 'none',
      borderBottom: `2px solid ${theme.colors.grey5}`,
    },
    activeCategory: {
      borderBottom: `2px solid ${theme.colors.cyan}`,
      fontWeight: 700,
    },
    btnContainer: {
      marginTop: 90,
    },
  })
);
