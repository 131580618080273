import { useParams, useHistory } from 'react-router-dom';
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import useSWR from 'swr';
// components
import { Spinner } from 'components/Spinner';
import BackLink from 'components/BackLink';
import Header from '../components/Header';
// api
import blogApi from 'utils/blogApi';
// styles
import { useStyles } from './styles';

const Post = () => {
  const classes = useStyles();

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const { data: postResponse, isValidating } = useSWR<any>(
    `/post${id}`,
    () => blogApi.getPost(id),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      onError: () => history.push('/'),
    }
  );

  if (isValidating) {
    return (
      <>
        <Header />
        <Grid container className={classes.container}>
          <Grid container>
            <Spinner />
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Header />
      <Grid container className={classes.container}>
        <Grid container className={classes.contentContainer}>
          <Grid container>
            <BackLink className={classes.backLink} />
          </Grid>
          <Grid container className={classes.titleContainer}>
            <Typography
              variant="h3"
              dangerouslySetInnerHTML={{ __html: postResponse?.data?.title }}
            />
          </Grid>
          <Grid container className={classes.dateContainer}>
            <Typography>{moment(postResponse?.data?.date).format('DD MMM YYYY')}</Typography>
          </Grid>
          <Grid container className={classes.content}>
            <div dangerouslySetInnerHTML={{ __html: postResponse?.data?.content }} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Post;
