import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
// styles
import { useStyles } from './styles';

interface IProps {
  item: any;
}

const PostCart: FC<IProps> = ({ item }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} direction="column">
      <Grid container className={classes.imgContainer}>
        <img src={item?.post_thumbnail?.guid} alt="" />
      </Grid>
      <Grid className={classes.content} container direction="column">
        <Grid container>
          <Typography
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: item.title.replace(/\s/g, ' ') }}
          />
        </Grid>
        <Grid container>
          <Typography className={classes.date} color="inherit">
            {moment(item.date).format('DD MMM YYYY')}
          </Typography>
        </Grid>
        <Grid container>
          <div
            className={classes.excerpt}
            dangerouslySetInnerHTML={{ __html: item.excerpt.replace(/\s/g, ' ') }}
          />
        </Grid>
        <Grid>
          <Link to={`/posts/${item.ID}`} className={classes.link}>
            Read more
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PostCart;
