import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
// root page
import App from 'pages/App';
// service worker
import * as serviceWorker from './serviceWorker';
// material-ui theme
import theme from './styles/theme';
// global styles
import './index.css';
import 'fonts/stylesheet.css';

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
