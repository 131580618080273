import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    headerText: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      color: theme.colors.cyan,
      width: '100%',
      textAlign: 'center',
    },
    headerImgContainer: {
      position: 'relative',
    },
    headerImg: {
      width: '100%',
      minHeight: 400,
      objectFit: 'cover',
      objectPosition: 'center',
      [theme.breakpoints.down('sm')]: {
        minHeight: 200,
      },
    },
  })
);
