import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: '50px 10% 100px',
      [theme.breakpoints.down('sm')]: { padding: '50px 50px 100px' },
      [theme.breakpoints.down('sm')]: { padding: '50px 20px 70px' },
    },
    backLink: {
      margin: '20px 0',
    },
    titleContainer: {
      marginBottom: 20,
    },
    dateContainer: {
      marginBottom: 20,
    },
    contentContainer: {
      [theme.breakpoints.up('md')]: {
        maxWidth: 1024,
        margin: '0 auto',
      },
    },
    content: {
      '& ol, & ul': {
        listStylePosition: 'inside',
      },
      '& p': {
        margin: '15px 0',
      },
    },
  })
);
