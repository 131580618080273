import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
// icons
import { ReactComponent as ArrowLeftLongBlue } from 'assets/icons/origin/ArrowLeftLondBlue.svg';
// styles
import useStyles from './styles';

interface IProps {
  title?: string;
  onClick?: () => void;
  className?: string;
}
const BackLink: FunctionComponent<IProps> = ({ title = 'Back', onClick, className }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    // @ts-ignore
    history.goBack();
  }, [history]);

  return (
    <div onClick={handleGoBack || onClick} className={cx(classes.backLink, className)}>
      <ArrowLeftLongBlue />
      <span>{title}</span>
    </div>
  );
};

export default BackLink;
